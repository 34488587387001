import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/global.css";
import TopBar from "./components/TopBar";
import Footer from "./components/Footer";
import { BrowserRouter } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <TopBar />
      <App />
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);
