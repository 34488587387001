import React from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";

const Contact = () => {
  return (
    <>
      <div style={{ backgroundColor: "#293A5B", color: "white" }}>
        <Container className="py-5">
          <Col xs={12} lg={10} className="mx-auto py-5">
            <Row>
              <Col xs={12} lg={6}>
                <Col xs={12} lg={9} className="mx-auto">
                  <div className="contact-form">
                    <h2>LET'S GET IN TOUCH</h2>
                  </div>
                  <div className="py-5">
                    <div className="d-flex ">
                      <div className="icon p-2 mx-1">
                        <svg
                          width="30"
                          height="30"
                          fill="#ffffff"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M19.875 3.75H4.125A2.628 2.628 0 0 0 1.5 6.375v11.25a2.628 2.628 0 0 0 2.625 2.625h15.75a2.627 2.627 0 0 0 2.625-2.625V6.375a2.627 2.627 0 0 0-2.625-2.625Zm-.665 4.342-6.75 5.25a.75.75 0 0 1-.92 0l-6.75-5.25a.75.75 0 1 1 .92-1.184L12 11.8l6.29-4.892a.75.75 0 0 1 .92 1.184Z"></path>
                        </svg>
                      </div>
                      <div className="mx-2">
                        <h5 className="m-0">Email Address</h5>
                        <small>Support@sdsdispatch.com</small>
                      </div>
                    </div>
                    <div className="d-flex my-4">
                      <div className="icon p-2 mx-1">
                        <svg
                          width="30"
                          height="30"
                          fill="#ffffff"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M22.45 17.35c-.244-.26-1.108-1.031-2.697-2.05-1.6-1.03-2.78-1.67-3.118-1.82a.18.18 0 0 0-.183.023 59.029 59.029 0 0 0-1.513 1.248c-.317.273-.317.273-.578.188-.457-.15-1.878-.905-3.117-2.146-1.238-1.241-2.032-2.698-2.182-3.155-.085-.26-.085-.26.188-.578.042-.05.823-.967 1.249-1.512a.18.18 0 0 0 .022-.184c-.149-.339-.79-1.518-1.819-3.118-1.02-1.588-1.79-2.451-2.05-2.696a.183.183 0 0 0-.183-.04 15.11 15.11 0 0 0-2.625 1.192 15.844 15.844 0 0 0-2.28 1.61.18.18 0 0 0-.06.176c.098.456.566 2.362 2.02 5.002 1.482 2.694 2.51 4.075 4.687 6.245 2.177 2.17 3.601 3.259 6.299 4.742 2.64 1.453 4.546 1.921 5.002 2.018a.181.181 0 0 0 .176-.059 15.83 15.83 0 0 0 1.61-2.28c.48-.835.88-1.714 1.193-2.625a.184.184 0 0 0-.04-.181Z"></path>
                        </svg>
                      </div>
                      <div className="mx-2">
                        <h5 className="m-0">Call Us</h5>
                        <small>281-800-5860</small>
                      </div>
                    </div>
                    <div className="d-flex ">
                      <div className="icon p-2 mx-1">
                        <svg
                          width="30"
                          height="30"
                          fill="#ffffff"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 1.5c-4.14 0-7.5 3.024-7.5 6.75 0 6 7.5 14.25 7.5 14.25s7.5-8.25 7.5-14.25c0-3.726-3.36-6.75-7.5-6.75ZM12 12a3 3 0 1 1 0-5.999A3 3 0 0 1 12 12Z"></path>
                        </svg>
                      </div>
                      <div className="mx-2">
                        <h5 className="m-0">Address</h5>
                        <small>30 N Gould St Ste R Sheridan , Wyoming 82801</small>
                      </div>
                    </div>
                  </div>
                </Col>
              </Col>
              <Col xs={12} lg={6}>
                <div className="pt-2">
                  <Card
                    className="px-3 py-5 text-center"
                    style={{ backgroundColor: "#322C84", color: "white" }}
                  >
                    <b>SEND MESSAGE</b>

                    <Form.Group className="my-3">
                      <Form.Control placeholder="Your Name" />
                    </Form.Group>
                    <Form.Group className="my-3">
                      <Form.Control placeholder="Email Address" type="email" />
                    </Form.Group>

                    <div class="form-group">
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="4"
                        placeholder="Message"
                      ></textarea>
                    </div>
                    <Button className="mt-3 message">Send Message</Button>
                  </Card>
                </div>
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    </>
  );
};

export default Contact;
