import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import team from "../../assets/team.jpg";

const AdditionalSide = () => {
  const sideBySideData = [
    {
      title: "OUR EXPERTISE DOESN'T STOP ON DISPATCH ALONE, WE OFFER A FULL OFFICE SOLUTION FOR YOUR TRUCKING BUSINESS.",
      text1:
        "Our dispatchers have the time, experience, and knowledge to find the best paying truck loads so you can concentrate on the road or better yet, get some deserved rest before your next run. It is important for a driver to be well rested and energized to perform at his best. If you lose time looking for the loads yourself, even if you find a great paying load, the chances are you will not make far, since you did not rest resulting loosing more money in the long run. Also, please be safe all the time and do not try to search a load while moving. Stay professional and let our experts do the hassle of constantly monitor load boards, make phone calls, handle paperwork and conduct all the broker communication.",
    
      buttonText: "CALL US TODAY",
      imageSrc: team,
    },
  ];

  return (
    <>
     
          {sideBySideData.map((data) => (
            <Row className="py-5">
              <Col xs={12} lg={6}>
                <div className="side">
                  <h2 className="pb-2">{data.title}</h2>
                  <p className="text-muted">{data.text1}</p>
                  <p className="text-muted">{data.text2}</p>
                  <Button className="contact">{data.buttonText}</Button>
                </div>
              </Col>
              <Col xs={12} lg={6} className="mx-auto">
                <Image style={{height:"500px"}}  src={data.imageSrc} width={"100%"} />
              </Col>
            </Row>
          ))}

       
    </>
  );
};

export default AdditionalSide;
