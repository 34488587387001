import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import record from "../../assets/record.png";
const EquipmentsSide = () => {
  const sideBySideData = [
    {
      title: "WHAT IS A TRUCK DISPATCHER SERVICE?",
      text1:
        "A truck dispatcher service is a cost effective way to assists Owner Operators and Trucking Companies with daily operations such as find loads, negotiate the best possible rates, managing drivers, scheduling shipper and receiver appointments and other back-end office duties without the liability for the owner to hire employees or setup a physical office. In other words, you can have a fully operational virtual office and complete dispatcher team on a pay-as-you-need basis.It’s a smart and risk free strategy to have all the needed resources without paying a fortune. ",

      buttonText: "CALL US",
      imageSrc: record,
    },
  ];

  return (
    <>
   
          {sideBySideData.map((data) => (
            <Row className="py-5">
              <Col xs={12} lg={6}>
                <div className="side">
                  <h2 className="pb-2">{data.title}</h2>
                  <p className="text-muted">{data.text1}</p>
                  <p className="text-muted">{data.text2}</p>
                  <Button className="contact">{data.buttonText}</Button>
                </div>
              </Col>
              <Col xs={12} lg={6} className="mx-auto ">
                <Image style={{height:"350px"}} src={data.imageSrc} width={"100%"} />
              </Col>
            </Row>
          ))}

         
    </>
  );
};

export default EquipmentsSide;
