import React from 'react'
import SideDispatch from '../../components/service/SideDispatch'
import Contact from '../../components/home/Contact'
import ServiceHeading from '../../components/service/ServiceHeading'

const DispatchService = () => {
  return (
    <>
        <ServiceHeading title={"Truck Dispatch Service"} />
        <SideDispatch/>
        <Contact/>
    </>
  )
}

export default DispatchService