import React from "react";
import {
  Button,
  Container,
  Form,
  Image,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/trucklogo.png";
const links = [
  { label: "Home", to: "/" },
  { label: "About", to: "/about" },
  {
    label: "Service",
    dropdown: [
      { label: "Equipment We Dispatch", to: "/service/equipments-services" },
      { label: "Truck Dispatch Service", to: "/service/truck-dispatch" },
      {
        label: "Additional Services We Offer",
        to: "/service/additional-services",
      },
    ],
  },
  { label: "Contact", to: "/contact" },
];

const TopBar = () => {
  return (
    <>
      <Navbar expand="lg" className="bg-transparent-tertiary ">
        <Container className="py-2">
          <Navbar.Brand as={Link} to={"/"} style={{width:"100px"}}>
           
              <Image src={logo}  width={"100%"} />
     
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className=" my-2 my-lg-0 top-bar-links"
              style={{ maxHeight: "100px", marginLeft: "auto" }}
              navbarScroll
            >
              {links.map((link, index) => (
                <React.Fragment key={index}>
                  {link.dropdown ? (
                    <NavDropdown
                      className="mx-3 mt-2"
                      title={link.label}
                      id={`navbarDropdown${index}`}
                    >
                      {link.dropdown.map((dropdownItem, dropdownIndex) => (
                        <NavDropdown.Item
                          key={dropdownIndex}
                          as={Link}
                          to={dropdownItem.to}
                        >
                          {dropdownItem.label}
                        </NavDropdown.Item>
                      ))}
                    </NavDropdown>
                  ) : (
                    <Nav.Link
                      as={Link}
                      className="mx-3 mt-2"
                      to={link.to}
                      disabled={link.disabled}
                      isActive
                    >
                      {link.label}
                    </Nav.Link>
                  )}
                </React.Fragment>
              ))}
            </Nav>
            <Form className="d-flex">
              <Button className="mx-2 contact">281-800-5860</Button>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default TopBar;
