import React from "react";
import DispatchRoutes from "./routes";

const App = () => {
  return (
    <>
      <DispatchRoutes/>
    </>
  );
};

export default App;
