import React from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Row,
} from "react-bootstrap";
import truck from "../../assets/highway.jpg";
import Heading from "../Heading";
import image1 from "../../assets/heading.jpg"
const AboutSide = () => {
  const sideBySideData = [
    {
      title: "OUR EXPERINCE MAKES US THE BEST",
      text1:
        "We spend significant time in assisting transporters with finding the top-paying cargo loads for their trucks. Our dispatchers arrange the most noteworthy rates and advise you regarding choices to settle on the last choice.",
      text2:
        "There is an explanation The skyline dispatch turned into the business chief in dispatch administration. We assist our transporters with moving more cargo securely and cost-successfully consistently! Furthermore, we offer significantly more that might be of some value than simply dispatching. We work with you, as your accomplice, to give the apparatuses you want to keep your trucks stacked and productive.",
      text3:
        "Organizations like yours trust us for all their cargo dispatching. We convey the upper hand you really want while dispensing with the vulnerability and intricacy of your cargo conveyance. Our administration permits you to zero in on your business.",
      text4:
        "A committed dispatcher is doled out to each relationship, turning into a solitary resource. Your dispatcher knows your transportation business personally and consistently pays special attention to your business’ wellbeing",
      buttonText: "Contact US",
      imageSrc: truck,
    },
  ];

  return (
    <>
 <Heading title="WHO WE ARE?" backgroundImage={image1} />
      <Container className="py-5">
        <Col xs={12} lg={10} className="mx-auto py-3">
          {sideBySideData.map((data) => (
            <Row>
              <Col xs={12} lg={6} className="mb-2">
                <div className="side">
                  <h2 className="pb-2">{data.title}</h2>
                  <p className="text-muted">{data.text1}</p>
                  <p className="text-muted">{data.text2}</p>
                  <p className="text-muted">{data.text3}</p>
                  <p className="text-muted">{data.text4}</p>
                  <Button className="contact">{data.buttonText}</Button>
                </div>
              </Col>
              <Col xs={12} lg={6} className="mx-auto">
                <Image
                  src={data.imageSrc}
                  style={{ height: "650px" }}
                  width={"100%"}
                />
              </Col>
            </Row>
          ))}
        </Col>
      </Container>

      <div className="py-5" style={{ backgroundColor: "#F9F9F9" }}>
      <Container>
        <Col xs={12} lg={10} className="mx-auto">
          <Row className="py-5">
            <Col xs={12} lg={7}>
              <div className="side">
                <h2>
                  WE ADVISE GLOBAL LEADERS ON THEIR MOST CRITICAL ISSUES AND
                  OPPORTUNITIES.
                </h2>
              </div>
            </Col>
            <Col
              xs={12}
              lg={5}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <Button className="contact">GET IN TOUCH</Button>
              </div>
            </Col>
          </Row>
        </Col>
        </Container>
      </div>
    </>
  );
};

export default AboutSide;
