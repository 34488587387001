import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
    <hr className="text-muted m-0" />
      <div style={{ backgroundColor: "#2A3B5C" ,color:"white" }}>
        <Col xs={12} lg={8} className="mx-auto text-center">
          <div className="py-3">
            <p className="m-0">
            30 N Gould St Ste R Sheridan , Wyoming 82801| Phone: 281-800-5860|
              Email: support@sdsdispatch.com
            </p>
            <p className="m-0"><Link className="text-decoration-none text-white" to="privacypolicy">privacy & policy</Link></p>
            <p className="m-0">Copyright © The SDS Dispatch</p>
          </div>
        </Col>
      </div>
    </>
  );
};

export default Footer;
