import React from "react";
import Hero from "../components/home/Hero";
import HomeCards from "../components/home/HomeCards";
import SidebySide from "../components/home/SidebySide";
import Customer from "../components/home/Customer";
import Contact from "../components/home/Contact";

const Home = () => {
  return (
    <>
      <Hero />
      <HomeCards />
      <SidebySide />
      <Customer />
      <Contact />
    </>
  );
};

export default Home;
