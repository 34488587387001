import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import icon1 from "../../assets/icon1.png";
import icon2 from "../../assets/icon2.png";
import icon3 from "../../assets/icon3.png";
const EquipmentExpertiseCard = () => {
  const cardsData = [
    {
      image: icon1,
      title: "What Equipment We Dispatch",
    },
    {
      image: icon2,
      title: "Truck Dispatch Service Plans",
    },
    {
      image: icon3,
      title: "Additional Services We Offer",
    },
  ];
  return (
    <>
      <div className="text-center expertise pt-3">
        <h2>Our Expertise & Services</h2>
        <p className="text-muted">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </p>
      </div>
      <Row className="py-5">
        {cardsData.map((card, index) => (
          <Col key={index} xs={4}>
            <Card className="border-0 py-2 text-center">
              <div style={{ width: "100px", height: "100px", margin: "auto" }}>
                <Card.Img variant="top" width={"100%"} src={card.image} />
              </div>

              <Card.Body className="px-0">
                <Card.Title>
                  <h3 style={{ fontWeight: "700", color: "#334363" }}>
                    {card.title}
                  </h3>
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default EquipmentExpertiseCard;
