import React from "react";
import AdditionalCards from "../../components/service/AdditionalCards";
import { Col, Container } from "react-bootstrap";
import ServiceHeading from "../../components/service/ServiceHeading";
import AdditionalSide from "../../components/service/AdditionalSide";
const AdditionalServices = () => {
  return (
    <>
     <ServiceHeading title={"Additional Services We Offer"} />
      <Container>
        <Col xs={12} lg={10} className="mx-auto">
        <AdditionalSide/>
          <AdditionalCards />
        </Col>
      </Container>
    </>
  );
};

export default AdditionalServices;
