import React from "react";
import { Button, Col, Container } from "react-bootstrap";

const Hero = () => {
  return (
    <>
      <Container className="py-5">
        <Col xs={12} lg={10} className="mx-auto hero">
          <div className="text-center">
            <h1 className="py-3">The SDS Dispatch</h1>
            <p>To give you a complete piece of mind, we are a fully registered US-based company and upon working with us, we will provide our complete document package along with EIN. Besides,</p>
            <Button className="contact my-3">Contact Us</Button>
          </div>
        </Col>
      </Container>
    </>
  );
};

export default Hero;
