import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import truck from "../../assets/truck.png";
import left from "../../assets/left.jpg";
const SidebySide = () => {
  const sideBySideData = [
    {
      title: "WHY WE ARE THE BEST CHOICE?",
      text1:
        "Our accomplished dispatchers can likewise be enhanced expansions to your current group, with a full scope of obligations yet just for a small part of the expense. Besides, we can undoubtedly deal with your hard-to-fill late-night or night shift, giving proficient experience and consumer loyalty. Our dispatchers are affirmed by industry subject matter experts.",
      text2:
        "Finding top-paying burdens is our strength, you should simply focus on driving. Allow us to deal with all the calls, haggle best rates with representatives, send load messages, finish up exhausting administrative work, sort out customary paths, resolve all issues, and in particular set aside your cash with our low-expense bundle plans and positively no agreement.",
      buttonText: "EMAIL US",
      imageSrc: truck,
    },
  ];

  return (
    <>
      <Container className="py-3">
        <Col xs={12} lg={10} className="mx-auto py-3">
          {sideBySideData.map((data) => (
            <Row>
              <Col xs={12} lg={6}>
                <div className="side">
                  <h2 className="pb-2">{data.title}</h2>
                  <p className="text-muted">{data.text1}</p>
                  <p className="text-muted">{data.text2}</p>
                  <Button className="contact">{data.buttonText}</Button>
                </div>
              </Col>
              <Col xs={12} lg={5} className="mx-auto">
                <Image  src={data.imageSrc} width={"100%"} />
              </Col>
            </Row>
          ))}

          <Row className="py-3">
            <Col xs={12} lg={6} className="mx-auto">
              <Image style={{height:"400px"}} src={left} width={"100%"} />
            </Col>
            <Col xs={12} lg={6}>
              <div className="side">
                <h2 className="pb-2">OUR DISPATCHING SERVICES ARE TOP NOTCH</h2>
                <p className="text-muted">
                  To give you complete piece of mind, we are a fully registered
                  US-based company and upon working with us, we will provide our
                  complete document package along with EIN. Besides, we have an
                  actual physical location in the Chicago area, so you can visit
                  us anytime. But what will give you the most convenience is our
                  easy Zelle payment transfers to the Chase bank with zero fees
                  for you and with the bank you trust.
                </p>
                <p className="text-muted">
                  These are our competitive advantages compared to many other
                  shady companies using international wires, Western Unions,
                  without W-9, and not even a physical presence in the US. Think
                  about it!
                </p>
                <Button className="contact">CALL US</Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Container>
    </>
  );
};

export default SidebySide;
