import React from 'react'
import { Col, Container } from 'react-bootstrap'
import EquipmentsCards from '../../components/service/EquipmentCard'
import ServiceHeading from '../../components/service/ServiceHeading'
import EquipmentsSide from '../../components/service/EquipmentsSide'
import EquipmentExpertiseCard from '../../components/service/EquipmentExpertiseCard'
import Contact from '../../components/home/Contact'

const EquipmentsServices = () => {
  return (
    <>
    <ServiceHeading title={"Equipment We Dispatch"} />
        <Container>
            <Col xs={12} lg={10} className='mx-auto'>
            <EquipmentsSide/>
            <EquipmentExpertiseCard/>
            <EquipmentsCards/>
            </Col>
        </Container>
        <Contact/>
    </>
  )
}

export default EquipmentsServices