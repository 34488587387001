import React, { useEffect } from "react";
import {
    Container,
} from "react-bootstrap";
import image1 from "../assets/privacy.jpg"
import Heading from "../components/Heading";
const PrivacyandPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <Heading title="PRIVACY & POLICY" backgroundImage={image1} />
            <Container className="py-5">

                <p>This Privacy Policy describes how we collect, use, and disclose the Personal Data you provide through the website <a className="text-muted text-decoration-none" href="https://www.sdsdispatch.com/">https://www.sdsdispatch.com/</a> (“Site”).</p>
                <p>This Privacy Policy also describes the choices available to you regarding our use of your Personal Data and how you can access the information. We respect your privacy, and we take protecting it seriously.</p>
                <p>This Site is operated by Supply Chain Mgmt Inc. (a company duly registered under the laws of the State of Delaware, with principal offices at 30 N Gould St Ste R Sheridan , Wyoming 82801 and its affiliates (collectively the “Provider”, “we”, or “us”) on its own behalf or behalf of its affiliates.</p>
                <p>The Privacy Policy governs how the Provider uses, collects, and discloses the information gathered from or provided by users of this Site located in the United States.</p>

                <p>Please read this Privacy Policy carefully to understand our policies and practices regarding your information and how we will treat it. If you disagree with this Privacy Policy, you should refrain from using our Site.</p>
                <p>By accessing or using our Site, you agree to this Privacy Policy.</p>
                <p><strong>1. Definitions</strong></p>
                <p><strong>1.1. “Personal Data”</strong> herein shall mean information related to an identified or identifiable natural person;</p>

                <p><strong>1.2. “GDPR”</strong>  means EU General Data Protection Regulation;</p>
                <p><strong>1.3. “CCPA”</strong>  means California Consumer Privacy Act.</p>
                <p><strong>2. Use by Users from the United States and Outside the United States</strong></p>
                <p><strong>2.1.</strong>   The Site is intended for use only by users located in the United States .</p>
                <p><strong>2.2.</strong>  If you are located in the United States, by providing Personal Data to us, you consent to the processing, including collecting, storing, using, disclosing, sharing, and transferring your Personal Data provided to us, as prescribed in this Privacy Policy.</p>
                <p><strong>2.3.</strong>   If you are located outside of the United States (other than for users located in USA), by providing Personal Data to us, you are consenting to the transfer and/or transmission of your information into the United States or to the location of the Provider’s data storage facilities which may exist outside of the United States, and to the collection, use, and disclosure of your information in accordance with this Privacy Policy. You also acknowledge that the laws of the jurisdiction where the data is kept will determine the protections afforded to your Personal Data and that such protections may not be as strict as your home jurisdiction. The Provider stores and processes the Personal Data it gathers through your use of the Site in the United States or in the location of the Provider’s data storage facilities, which may exist outside the United States.</p>
                <p><strong>2.4.</strong>   We do not intentionally collect data from EU persons. If we acknowledge that a person from the EU provided Personal Data to us, it will be maintained as prescribed in Section 12, “Users from the EU”, below.</p>
                <p><strong>2.5.</strong>  If you are located in USA, then the terms of this Privacy Policy, as amended and supplemented by the Canadian Privacy Policy Statement contained herein, govern your use of this Site.</p>
                <p><strong>3. Scope of this Privacy Policy</strong></p>
                <p><strong>3.1.</strong> This Privacy Policy applies to the Site, and it does not apply to other websites, including other websites operated by the Provider, its Affiliates or service providers, or sites that may be operated by others, each of which is operated and managed by a separate company under separate terms and conditions together with separate privacy policy, except as otherwise specified on those websites. When visiting other websites, even via links provided through the Site, please review the applicable privacy policies to be sure their terms are acceptable to you.</p>

                <p><strong>4. The Information We Collect</strong> </p>
                <p><strong>4.1. </strong>We may collect the following Personal Data: a user’s full name, email address, address, and phone number. We may also collect business information from users, such as employment or business experience, including the types of trucks used. </p>
                <p><strong>4.2.</strong> We collect Personal Data when you directly provide it to us, such as when you send us information via email, contact forms available on the Site, or other electronic message services. IF YOU DO NOT WANT US TO PROCESS PERSONAL DATA FROM YOU, PLEASE DO NOT PROVIDE IT TO US.</p>
                <p><strong>5. The Information We Collect Automatically</strong> </p>
                <p><strong>5.1.</strong> We may also collect information automatically as users navigate through and utilize the Site, including information about a user’s equipment and information about a user’s computer, Internet connection, browsing and usage actions, and patterns of the Site, including as follows:</p>
                <p>(a) We record general statistical and tracking information, such as the date and time the Site is accessed, the features of the Site viewed or utilized, and the time spent using the Site; </p>

                <p>(b) We receive and record information about a user’s computer and its systems and in our server logs from a user’s browser, including an IP address (the Internet address of a computer), identification of the computer and its systems, the type and version of the web browser, referrer addresses and other server log information. IF YOU DO NOT WANT US TO COLLECT INFORMATION FROM YOUR COMPUTER OR MOBILE DEVICE, DO NOT USE THE SITE. </p>
                <p><strong>5.2. </strong>We may also collect information automatically when you interact with our advertising and applications on third-party websites and services if those applications or advertising include links to this Privacy Policy, including when such websites are operated by the Provider or our Affiliates. </p>
                <p><strong>5.3.</strong> The processing of such data is based on a legitimate interest and is performed only for the purposes specified in this Privacy Policy — to analyze the data collected for market research purposes.</p>
                <p><strong>6. Cookies</strong> </p>
                <p><strong>6.1.</strong>We use cookies and other tracking technologies to recognize when users return to the Site. A cookie is a small amount of data, which often includes an anonymous unique identifier, which is sent to a user’s browser from a site’s computer and stored on a user’s computer’s hard drive. With respect to most computer systems, you may set your browser so that it does not accept cookies. If you do disable the use of cookies, you may not be able to use or experience some of the features of our Site.

                </p>
                <p><strong>6.2.</strong> Cookies are used for the purposes specified in this Privacy Policy — to provide a greater and better experience with us and for other related purposes.</p>
                <p><strong>6.3.</strong>We provide you with the option to decline all Cookies, except strictly necessary Cookies, by choosing “Reject All” in the Site’s Cookie Settings. Please note if you choose to opt out of the use of Cookies, you may still receive an opt-out Cookie so that your decision is saved. If you regularly delete your Cookies in your browser, the opt-out Cookie may be deleted as well. You will have to decide again whether to opt out of the use of Cookies or to accept them for use on our Site. </p>

                <p><strong>6.4. </strong> We use Strictly Necessary Cookies required to navigate our Site and to operate the basic functions of the Site. Examples of Strictly Necessary Cookies are cookies to remember your cookie preferences or to access secure areas of the Site. Without these Cookies, certain basic functions cannot be offered. Strictly Necessary Cookies are always active and will be placed without your consent. These Cookies cannot be disabled.</p>
                <p><strong>6.5.</strong>We use Statistics Cookies to collect information about how you use our Site. They identify, for example, your browser, operating system, the websites visited, the duration and number of visits to the website, the websites you have visited before, the websites you have visited most often, and the errors you have encountered during visits. The information collected is aggregated and anonymous. It does not allow the identification of a person. It only serves the purpose of evaluating and improving the usability of our Site. </p>
                <i><strong>Please note We do not use Marketing Cookies and Preference Cookies.</strong> </i>
                <p><strong>6.6.</strong>  We use Cookies set by our Site (displayed in the URL window) (First-Party Cookies) and Cookies set by a third-party domain (not by our Site) (Third-Party Cookies).</p>

                <p><strong>6.7. </strong> Such third-party services place their Cookies:</p>
                <p>(i) Google Analytics. Types of Cookies used: security, processes, session state, functionality, and analytics. Google uses the data collected to track and analyze the use of our Site, prepare reports on its operation, and share them with other Google services. Google may use the Personal Data collected to contextualize and personalize the advertising of its advertising network. Personal Data collected: Cookies and Usage Data. Place of processing: USA — Privacy Policy — Opt Out.</p>

                <p>(ii) Google Ads. Types of Cookies used: security, processes, session state, functionality, and analytics. Google uses the data collected to send our advertisements to persons who have already interacted with our Site (e.g., visitors), prepare reports on the effectiveness of advertisements, and share them with other Google services. Google may use the Personal Data collected to contextualize and personalize the advertising of its advertising network. Personal Data collected: Cookies and Usage Data. Place of processing: USA — Privacy Policy — Opt Out Information.</p>
                <p>(iii) Microsoft Clarity. Types of Cookies used: security, processes, session state, functionality, and analytics. Personal Data collected: Cookies and Usage Data. Privacy Policy — Opt Out.</p>



                <p>(iv) Meta Pixel. Meta uses the data collected to track and analyze the use of our Site, prepare reports on its operation, and share them with other Meta services. Meta Pixel may use the Personal Data collected to contextualize and personalize the advertising of its advertising network. Personal Data collected: Cookies and Usage Data. Place of processing: USA — Privacy Policy — Opt Out.
                </p>

                <p>



                    (v) Weblow. Types of Cookies used: session state, functionality, and analytics. Personal Data collected: Cookies and Usage Data. Privacy Policy — Opt Out Information.
                </p>
                <p>
                    (vi) Hotjar. Types of Cookies used: statistics. It helps us create statistics about visitors to the SIte. Personal Data collected: Cookies and Usage Data. Privacy Practices; Privacy Policy — Opt Out Information.
                </p>


                <p><strong>6.8.</strong> Considering the objective difficulty associated with the identification of technologies based on Cookies, we advise you to contact us if you wish to receive additional information about our use of Cookies.</p>



                <p><strong>7. Security </strong> </p>
                <p><strong>7.1.</strong> We have put in place security systems designed to prevent unauthorized disclosure, loss, misuse, or destruction of the data you provide to us. These systems are structured to deter and prevent hackers and others from accessing this information.</p>

                <p><strong>7.2. </strong>Due to the nature of Internet communications and evolving technologies, there is no 100% secure method of transmission over the Internet or method of electronic storage. Although we do our best to protect your information, we cannot guarantee the security of your information exchanged through the Site. We cannot provide, and we hereby disclaim, assurance that the data you provide us will remain absolutely secure.</p>

                <p><strong>8. Children</strong></p>
                <p><strong>8.1.</strong> The Site is not directed to or intended to be used by persons under the age of eighteen (18), and the Provider does not knowingly collect Personal Data from persons under such age. If we find out that an individual under the age of 18 has provided us with Personal Data through this Site, we will use reasonable efforts to delete such information from our databases as quickly as possible.</p>
                <p><strong>9. Users from the EU </strong> </p>

                <p><strong>9.1.  </strong>If you are an EU person, you may have additional rights pursuant to the GDPR and your local law applicable to the collection and processing of Personal Data. </p>

                <p><strong>9.2.</strong> Right to access. You may contact us to get confirmation as to whether or not we are processing your Personal Data. When we process users’ Personal Data, we will inform users of what categories of Personal Data we process regarding them, the processing purposes, the categories of recipients to whom Personal Data have been or will be disclosed, and the envisaged storage period or criteria to determine that period.</p>

                <p><strong>9.3.</strong> Right to object. If you believe that the Provider or an Affiliate is collecting your Personal Data and your Personal Data is processed based on legitimate interests pursuant to the GDPR, you have the right to object to the processing at any time. We shall then no longer process your Personal Data unless we demonstrate other compelling legitimate grounds for our processing that override the user’s interests, rights, and freedoms or for legal claims. Notwithstanding any consent granted beforehand for direct marketing purposes, any user has the right to prohibit us from using their Personal Data for direct marketing purposes by contacting us.</p>

                <p><strong>9.4.</strong> Under the GDPR, you may also have the right to request to have your Personal Data restricted, ask for portability of your Personal Data, and update or correct your Personal Data.</p>

                <p><strong>9.5.</strong> In the event you consider our processing or control of your Personal Data subject to the GDPR and not compliant with an applicable data protection law, you can lodge a complaint:</p>


                <p>Contact us via email at <a className="text-muted text-decoration-none" href="mailto:support@sdsdispatch.com">Send email</a></p>

                <p>
                    <strong>


                        9. Data Retention and Deletion
                    </strong></p>
                <p><strong>9.1.</strong> We will retain Personal Data as long as you use our Site or continue communicating with our support team.</p>
                <p><strong>9.2.</strong>We will dispose of your Personal Data when it is no longer valid for the purposes for which it was provided to us by you.</p>

                <p><strong>9.3.</strong>When deciding what the correct time is to keep the data, we look at its amount, nature and sensitivity, potential risk of harm from unauthorized use or disclosure, processing purposes, if these can be achieved by other means, and legal requirements. For tax purposes, the law requires us to keep basic information about our users (including contact, identity, financial, and transaction data) for twelve (12) months after they stop being our users.</p>



                <p><strong>9.4.</strong>In some circumstances, we may anonymize and aggregate Personal Data for research or statistical purposes, in which case we may use, disclose, and transfer this information, as well as any information that does not identify an individual, indefinitely without further notice to our users.</p>

                <p><strong>10. Updating Contact and Personal Data</strong></p>
                <p><strong>10.1.</strong>By agreeing to this Privacy Policy, you agree to keep your contact information or other Personal Data we maintain up-to-date. If your contact information or other Personal Data that you provided through the Site needs to be updated, please promptly contact us at <a className="text-muted text-decoration-none" href="mailto:support@sdsdispatch.com">support@sdsdispatch.com</a> and provide sufficient information so that we can properly update your information.</p>

                <p><strong>11. Do Not Track Signals</strong></p>

                <p><strong>11.1.</strong>“Do Not Track” (“DNT”) is a preference you can set in your web browser to let the websites you visit know that you do not want them collecting data about you. Please check your browser settings to turn it on. Currently, this Site may not respond to DNT or similar signals.</p>

                <p><strong>12. SMS Opt-in Policy</strong></p>

                <p><strong>12.1. </strong>When you provide your personal information through our website or other communication channels, you may have the option to opt-in to receive SMS messages from SDS Dispatch LLC. By opting in, you consent to receive text messages containing informational or promotional content related to our services.</p>


                <p>We respect your privacy and do not share or sell SMS opt-in information or phone numbers for the purpose of SMS with third parties. Your opt-in information is used solely for the purpose of sending SMS messages directly from SDS Dispatch LLC.</p>

                <p>You may opt out of receiving SMS messages at any time by following the instructions provided in the SMS messages or by contacting us directly. Opting out of SMS messages does not affect your ability to receive other forms of communication from SDS Dispatch LLC.</p>

                <p><strong>13. Changes to this Privacy Policy</strong></p>
                <p><strong>13.1.</strong>From time to time, the Provider may change its Privacy Policy. We will post a notice of such changes, which notice will be accessible from the Privacy Policy link on our Site or may appear on the home page of the Site. Your use of the Site following any such change constitutes your agreement to follow and be bound by the Privacy Policy, as changed, and to its application to all information gathered before and after its effective date. We encourage you to periodically visit our Site and this Privacy Policy to check for any changes.</p>


                <p><strong>14. Contact Us</strong></p>
                <p><strong>14.1. </strong> Questions, comments, or requests related to your use of the Site regarding this Privacy Policy and the Provider’s information handling policies and procedures should be submitted to the Provider’s Corporate Communications department by mail or e-mail as follows:</p>
                <p>Address: 30 N Gould St Ste R Sheridan , Wyoming 82801</p>
                <p>By e-mail: <a className="text-muted text-decoration-none" href="mailto:support@sdsdispatch.com">support@sdsdispatch.com</a> </p>
                <p>To register a complaint or concern, please <strong>contact </strong><a className="text-muted text-decoration-none" href="https://www.sdsdispatch.com/"> https://www.sdsdispatch.com/</a></p>

            </Container>


        </>
    );
};

export default PrivacyandPolicy;
