import React from 'react'
import AboutSide from '../components/about/AboutSide'
import Contact from '../components/home/Contact'

const About = () => {
  return (
    <>
        <AboutSide/>
        <Contact/>
    </>
  )
}

export default About