import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import placeholdder from "../../assets/placeholder.png";
const EquipmentsCards = () => {
  const cardsData = [
    {
      title: "53' DRY VANS",
      description: "If you are looking for a proficient dry van dispatcher to manage your dry van freight, feasible consolidation or practical drop and hook. ",
    },
    {
        title: "53' REEFERS",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
      },
      {
        title: "53' FLATBEDS",
        description: "Whether you need a flatbed dispatcher to handle any type of flat bed, step deck of double-drop deck trailer or to move an over dimensional shipment. ",
      },
      {
        title: "CAR HAULERS",
        description: "Given you prefer a car hauler dispatcher to arrange transportation of vehicles in open or enclosed car haulers, with care from start to finish. ",
      },
      {
        title: "HOT SHOT",
        description: "Possibly you require a hot shot dispatcher to apprehend different means of transportation in a supply chain of various equipment.",
      },
      {
        title: "POWER ONLY",
        description: "Globalization makes you wish for a freight dispatcher in all aspects of international container shipment, with import and export declarations.",
      },
   
  ];
  return (
    <>
      <Row className="py-5">
        {cardsData.map((card, index) => (
          <Col key={index} sm={12} lg={4}>
            <Card className="border-0 py-2">
              <Card.Img variant="top" src={placeholdder} width={"100%"} />
              <Card.Body className="px-0">
                <Card.Title>{card.title}</Card.Title>
                <Card.Text className="text-muted" style={{textAlign:"justify"}}>{card.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default EquipmentsCards;
