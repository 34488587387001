import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import placeholdder from "../../assets/placeholder.png";
const AdditionalCards = () => {
  const cardsData = [
    {
      title: "TEAM ADDITION",
      description: "While still keeping your existing dispatchers you can use our additional help to run your operations. That’s a cost effective way to have more workforce on your team without further department of labor liability.",
    },
    {
        title: "LOAD BOOKERS",
        description: "Popular option with existing dispatchers is using our specialists adding more power to locate and book loads. Don’t forget you only pay commission, if there are no loads booked, you don’t pay, nothing to lose.",
      },
      {
        title: "AFTERHOURS",
        description: "Night or weekend shifts can be problematic to find willing candidates and often premium payment is required to fill the position. Time zone difference with one of our offices benefits you covering those shift with ease. ",
      },
      {
        title: "TRUCKING ACCOUNTING",
        description: "Accounting for truck drivers is essential for anyone involved in this industry, but this is a significant topic for owner-operators or independent.",
      },
      {
        title: "DOCUMENT MANAGEMENT",
        description: "Staying compliant on the road is very important and that means somebody has to monitor it. We have staff with experience developing Electronic Logging Device systems and can audit and advice your drivers.        ",
      },
      {
        title: "CUSTOM",
        description: "We can customize any plan to offer our services for your best needs. Meaning you also have access to all of our resources including over 80 professionals specializing in different aspects of trucking industry.",
      },
   
  ];
  return (
    <>
      <Row className="py-5">
        {cardsData.map((card, index) => (
          <Col key={index} sm={12} lg={4}>
            <Card className="border-0 py-2">
              <Card.Img variant="top" src={placeholdder} width={"100%"} />
              <Card.Body className="px-0">
                <Card.Title>{card.title}</Card.Title>
                <Card.Text className="text-muted" style={{textAlign:"justify"}}>{card.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default AdditionalCards;
