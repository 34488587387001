import React from 'react'
import Heading from '../components/Heading'
import image1 from "../assets/contact.jpg"
import Contact from '../components/home/Contact'
const ContactPage = () => {
  return (
    <>
         <Heading title="CONTACT US" backgroundImage={image1} />
        <Contact/>
    </>
  )
}

export default ContactPage