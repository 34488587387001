import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import ContactPage from "../pages/ContactPage";
import DispatchService from "../pages/service/DispatchService";
import AdditionalServices from "../pages/service/AdditionalServices";
import EquipmentsServices from "../pages/service/EquipmentsServices";
import PrivacyandPolicy from "../pages/PrivacyandPolicy";

const DispatchRoutes = () => {
  return (
    <>
      <Routes>
        <Route exact index path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<ContactPage/>} />
        <Route exact path="/service/truck-dispatch" element={<DispatchService/>} />
        <Route exact path="/service/additional-services" element={<AdditionalServices/>} />
        <Route exact path="/service/equipments-services" element={<EquipmentsServices/>} />
        <Route exact path="/privacypolicy" element={<PrivacyandPolicy/>} />
        
      </Routes>
    </>
  );
};

export default DispatchRoutes;
