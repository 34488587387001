import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import image from "../../assets/card2.jpg";
import image3 from "../../assets/card3.jpg";
const cardData = [
  {
    title: "TRUCK DISPATCH SERVICE PLANS",
    text: "Learn about the equipment we dispatch and learn more about why we are the best company to work with. Call us today.",
    linkText: "Go somewhere >",

    overlayColor: "rgba(72, 183, 235,0.9)",

    color: "white",
    homelink: "white",
    textcolor: "white",
    backgroundImage: image,
  },
  {
    title: "ADDITIONAL SERVICES WE OFFER",
    text: "Learn about the equipment we dispatch and learn more about why we are the best company to work with. Call us today.",
    linkText: "Go somewhere >",

    overlayColor: "rgba(29, 47, 82, 0.9)",
    color: "white",
    homelink: "white",
    textcolor: "white",
    backgroundImage: image3,
  },
];

const HomeCards = () => {
  return (
    <Col xs={9} className="mx-auto py-4">
      <Container>
        <Col xs={12} lg={6} className="mx-auto text-center">
          <div className=" text-center" style={{ textAlign: "center" }}>
            <h2 style={{fontWeight: 700, color: "#334363"}}>EQUIPMENT WE DISPATCH</h2>
            <p>
              Learn about the equipment we dispatch and learn more about why we
              are the best company to work with. Call us today.
            </p>
          </div>
        </Col>
        <Row>
          {cardData.map((card, index) => (
            <Col key={index} xs={12} lg={6}>
              <Card
                className="border-0 p-2 home-card p-4 rounded-0"
                style={{
                  backgroundImage: `url(${card.backgroundImage})`,
                  backgroundSize: "cover",
                  position: "relative",
                  color: card.color,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: card.overlayColor,
                    zIndex: 1,
                  }}
                ></div>
                <Card.Body style={{ position: "relative", zIndex: 2 }}>
                  <Card.Title className="py-2">{card.title}</Card.Title>
                  <Card.Text
                    className=" py-2"
                    style={{ color: card.textcolor }}
                  >
                    {card.text}
                  </Card.Text>
                  <Link
                    href="#"
                    style={{
                      color: card.homelink,
                      textDecoration: "none",
                      fontWeight: "600",
                    }}
                    className="py-2"
                    variant="link"
                  >
                    {card.linkText}
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Col>
  );
};

export default HomeCards;
