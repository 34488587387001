import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import truck from "../../assets/dispatchtruck.jpg";

const SideDispatch = () => {
  const sideBySideData = [
    {
      title: "BEST TRUCKING DISPATCH SERVICES FOR TRUCKING COMPANIES",
      text1:
        "Our experienced truck dispatchers set you up directly with the brokers so you can focus on driving. We handle all the necessary paperwork. No more worrying about follow-ups with the Broker for lumper and detention pay. Our in-house dispatchers take care of all admin work for you. You pick the areas you want to drive and we negotiate hard for the best paying rates and quality routes. You work directly with the best Shippers and Brokers and they pay you. Your dedicated truck dispatcher looks at multiple load boards to find you the best dry freight, flatbed, and reefer loads. The sds dispatch keeps you on the road so that you don’t leave your trucks stuck for days.",
    
      buttonText: "CALL US",
      imageSrc: truck,
    },
  ];

  return (
    <>
      <Container className="py-3">
        <Col xs={12} lg={10} className="mx-auto py-3">
          {sideBySideData.map((data) => (
            <Row>
              <Col xs={12} lg={6}>
                <div className="side">
                  <h2 className="pb-2">{data.title}</h2>
                  <p className="text-muted">{data.text1}</p>
                  <p className="text-muted">{data.text2}</p>
                  <Button className="contact">{data.buttonText}</Button>
                </div>
              </Col>
              <Col xs={12} lg={6} className="mx-auto">
                <Image style={{height:"450px"}}  src={data.imageSrc} width={"100%"} />
              </Col>
            </Row>
          ))}

          <Row className="py-5">
            <Col xs={12} lg={6} className="mx-auto">
              <Image style={{height:"450px"}} src={truck} width={"100%"} />
            </Col>
            <Col xs={12} lg={6}>
              <div className="side">
                <h2 className="pb-2">YOUR DEDICATED TRUCK DISPATCHER DOES ALL THE HEAVY LIFTING SO YOU CAN STAY FOCUSED ON DRIVING</h2>
                <p className="text-muted">
                The Horzion is a “Full Service” truck dispatching company that handles all of the back-office work so you can compete with the large fleets. Typically, a large carrier would hire somebody to handle all of these tasks, but it is unaffordable for a small carrier, that’s where The sds Dispatch comes in to help! Our process is simple, your dedicated truck dispatcher will work with you by your rules. We develop the best lanes that suit your desired schedule, whether you want to be home every night, every other night, or once every 2 weeks, our freight dispatchers work with you to develop a lane that will achieve your target goals.
                </p>
           
                <Button className="contact">CALL US</Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Container>
    </>
  );
};

export default SideDispatch;
