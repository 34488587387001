import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import image from "../../assets/shades.jpg"
const ServiceHeading = ({ title}) => {
  return (
    <>
      <div
        style={{
          position: "relative",
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(50, 44, 132,0.9)",
          }}
        ></div>
        <Container className="">
          <Row className="py-5">
            <Col xs={12} lg={8} className="mx-auto text-center">
              <div
                className="service-heading"
                style={{ position: "relative", zIndex: 1 }}
              >
                <h1 className="py-5">{title}</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ServiceHeading;
