import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";

const Customer = () => {
  const testimonials = [
    {
      text: "It's been a pleasure working with these guys. Their rates were great but more than that their customer service and happy attitude they have just brighten up your day.",
      author: "Jose M Gonzalez",
      number:"1189377",
      company_name:"Karina Express LLC"
    },
    {
      text: "I'll recommend them any day of the week to any truck owner because of how easy their process is. I have worked with many companies but I'm sticking with them for the long run.",
      author: "Anthony D Smith",
      number:"1426184",
      company_name:"C&A BOYZ TRUCKING LLC"
    },
  ];

  return (
    <>
      <Container className="py-5">
        <div className="customer text-center py-5">
          <h1>Satisfied Customers</h1>
        </div>
        <Col xs={12} lg={10} className="mx-auto">
          <Row>
            {testimonials.map((testimonial, index) => (
              <Col xs={12} lg={6} key={index}>
                <Card
                  className="border-0 rounded-0 p-3"
                  style={{ color: "#1D2F52" }}
                >
                  <Card.Body>
                    <Card.Text style={{textAlign:"justify"}}>{testimonial.text}</Card.Text>
                    <Card.Text>{testimonial.author}</Card.Text>
                    <b>MC: {testimonial.number}</b>
                    <div>
                    <b><i>{testimonial.company_name}</i></b>
                    </div>
                   
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Container>
      <div className="office">
        <Col xs={12} lg={6} className="mx-auto text-center">
          <h1>
            WE ADVISE GLOBAL LEADERS ON THEIR MOST CRITICAL DISPATCING ISSUES
            AND OPPORTUNITIES
          </h1>
          <Button className="contact">CONTACT US</Button>
        </Col>
      </div>
    </>
  );
};

export default Customer;
